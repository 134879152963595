.home {
  .top {
    position: relative;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;

    .overlay {
      background: rgb(255, 255, 255, 0.9);
      transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      position: absolute;
    }
  
    .top-content {
      position: relative;
      top: 63px;
  
      @include media-breakpoint-down(lg) {
        margin-top: 48px;
        top: 0;
      }

      @include media-breakpoint-down(md) {
        margin-top: 48px;
      }

      .top-banner {
        padding-bottom: 6rem;
        color: #606060;

        .demo-btn-wrapper {
          position: relative;
          padding-top: 3rem;
    
          .demo-btn {
            position: absolute;
          }

          @include media-breakpoint-down(lg) {
            margin-bottom: 6rem;
          }
        }
      }
  
      .description {
        color: #34C0B0;
        font-size: calc(1.275rem + .3vw);
        margin-top: 0;
        margin-bottom: 0.5rem;
        font-weight: 700;
        line-height: 1.2;
        background: linear-gradient(90deg, #FFCF21, #FFF3CA);
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }

      .sub-description {
        font-size: calc(1.275rem + .3vw);
        margin-top: 0;
        margin-bottom: 0.5rem;
        font-weight: 500;
        line-height: 1.2;
      }
    }
  }

  .integration {
    .card-group {
      @include media-breakpoint-down(md) {
        display: block;
      }

      .card {
        @include media-breakpoint-down(md) {
          width: 80%;
          margin: 0 auto;
        }

        img {
          border-radius: 15px !important;
        }
      }
    }
  }

  .support {
    background: #D9D9D98A;
  }
}

@include media-breakpoint-down(lg) {
  .home {
    height: 100%;
  }
}