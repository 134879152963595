// Fab
@keyframes phone-vr-circle-fill {
  0% {
      -webkit-transform: rotate(0) scale(1) skew(1deg)
  }

  10% {
      -webkit-transform: rotate(-25deg) scale(1) skew(1deg)
  }

  20% {
      -webkit-transform: rotate(25deg) scale(1) skew(1deg)
  }

  30% {
      -webkit-transform: rotate(-25deg) scale(1) skew(1deg)
  }

  40% {
      -webkit-transform: rotate(25deg) scale(1) skew(1deg)
  }

  50% {
      -webkit-transform: rotate(0) scale(1) skew(1deg)
  }

  100% {
      -webkit-transform: rotate(0) scale(1) skew(1deg)
  }
}
@-webkit-keyframes phone-vr-circle-fill {
  0% {
      -webkit-transform: rotate(0) scale(1) skew(1deg)
  }

  10% {
      -webkit-transform: rotate(-25deg) scale(1) skew(1deg)
  }

  20% {
      -webkit-transform: rotate(25deg) scale(1) skew(1deg)
  }

  30% {
      -webkit-transform: rotate(-25deg) scale(1) skew(1deg)
  }

  40% {
      -webkit-transform: rotate(25deg) scale(1) skew(1deg)
  }

  50% {
      -webkit-transform: rotate(0) scale(1) skew(1deg)
  }

  100% {
      -webkit-transform: rotate(0) scale(1) skew(1deg)
  }
}

@-webkit-keyframes zoom {
  0% {
      transform: scale(.9)
  }

  70% {
      transform: scale(1);
      box-shadow: 0 0 0 15px transparent
  }

  100% {
      transform: scale(.9);
      box-shadow: 0 0 0 0 transparent
  }
}

@keyframes zoom {
  0% {
      transform: scale(.9)
  }

  70% {
      transform: scale(1);
      box-shadow: 0 0 0 15px transparent
  }

  100% {
      transform: scale(.9);
      box-shadow: 0 0 0 0 transparent
  }
}

#button-contact-vr {
  position: fixed;
  bottom: 121px;
  right: -25px;
  z-index: 99999;

  &.is-scrolling {
    opacity: 0.2;
  }

  #phone-vr {
    transition: 1.6s all;
    -moz-transition: 1.6s all;
    -webkit-transition: 1.6s all;
    position: relative;
    margin-top: -5px;

    .phone-vr {
      position: relative;
      visibility: visible;
      background-color: transparent;
      width: 90px;
      height: 90px;
      cursor: pointer;
      z-index: 11;
      -webkit-backface-visibility: hidden;
      -webkit-transform: translateZ(0);
      transition: visibility .5s;
      left: 0;
      bottom: 0;
      display: block;

      .phone-vr-circle-fill {
        opacity: 0.7;
        top: 12px;
        left: 12px;
        position: absolute;
        border-radius: 50%;
        transition: all .5s;
        -webkit-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
      }

      .phone-vr-img-circle {
        background-color: #0d6efd;
        width: 3em;
        height: 3em;
        line-height: 50px;
        bottom: 5px;
        left: 5px;
        position: absolute;
        border-radius: 50%;
        border: 2px solid #0d6efd;
        overflow: hidden;
        display: flex;
        justify-content: center;

        a {
          display: block;
          line-height: 37px;
          background-color: #0d6efd;

          img {
            max-height: 25px;
            max-width: 27px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
          }
        }
      }
    }
  }

  .zalo-chat-widget {
    right: 7px !important;
    bottom: 10px !important;
  }

  #fb-messenger {
    transition: 1.6s all;
    -moz-transition: 1.6s all;
    -webkit-transition: 1.6s all;
    right: 7px;
    position: fixed;
    bottom: 65px;
    margin-top: -5px;

    .fb-messenger {
      position: relative;
      visibility: visible;
      background-color: transparent;
      width: 90px;
      height: 90px;
      cursor: pointer;
      z-index: 11;
      -webkit-backface-visibility: hidden;
      -webkit-transform: translateZ(0);
      transition: visibility .5s;
      left: 0;
      bottom: 0;
      display: block;

      .fb-messenger-circle-fill {
        opacity: 0.7;
        background-color: #0d6efd;
        top: 12px;
        left: 12px;
        position: absolute;
        box-shadow: 0 0 0 0 #ffffff;
        border-radius: 50%;
        transition: all .5s;
        -webkit-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
      }

      .fb-messenger-img-circle {
        background-color: #ffffff;
        width: 3em;
        height: 3em;
        line-height: 3em;
        bottom: 5px;
        right: 5px;
        position: absolute;
        border-radius: 50%;
        border: 1px solid #0d6efd;
        overflow: hidden;
        display: flex;
        justify-content: center;

        a {
          display: block;
          line-height: 37px;
          background-color: #0d6efd;

          svg {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 1.5em;
            height: 1.5em;
            transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
}